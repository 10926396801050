
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiCustomDeclarationExport, ApiShipments } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import FsLightbox from "fslightbox-vue/v3";
import {
  commonBackToList,
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  getOrderOfficialPrices,
  getOrderProductIdData,
  previewOrderImages,
} from "@/core/directive/function/order";
import { NumberOrString } from "@/core/directive/type/common";
import {
  ProductItemImage,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";
import { TaggingItem } from "@/core/directive/interface/common";
import { getExportDeclarationStatus } from "@/core/directive/function/exportDeclaration";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "declaration-management-export-declaration-information",
  components: {
    FsLightbox,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const id = route.params.id;
    const uploadUrl = ApiCustomDeclarationExport.uploadUrl();
    const uploadType = ref("");
    let childWin: any;

    const formData = ref({
      id: route.params.id,
      status: 10,
      system_id: "",
      declaration_export_no: "",
      shipment_id: "",
      export_handling_company: "",
      carrier_name: "",
      awb_number: "",
      declaration_date: null,
      due_date: null,
      approval_date: null,
      declaration_area: "",
      total_amount: "",
      total_quantity: "",
      customs_inspection: 0,
      customs_spot_check: 10,
      customs_inspection_address: null,
      customs_inspection_dates: null,
      customer_declaration_prefix: "",
      export_port: "",
      mrn_number: "",
      abd: 0,
      avm: 0,
      proforma_invoice: 0,
      packing_list: 0,
      tracking_no: "",
    });

    const options = ref({
      defaultFormData: {
        id: route.params.id,
        status: 10,
        system_id: "",
        declaration_export_no: "",
        shipment_id: "",
        export_handling_company: "",
        carrier_name: "",
        awb_number: "",
        declaration_date: null,
        due_date: null,
        approval_date: null,
        declaration_area: "",
        total_amount: "",
        total_quantity: "",
        customs_inspection: 0,
        customs_spot_check: 10,
        customs_inspection_address: null,
        customs_inspection_dates: null,
        customer_declaration_prefix: "",
        export_port: "",
        mrn_number: "",
        abd: 0,
        avm: 0,
        proforma_invoice: 0,
        packing_list: 0,
        tracking_no: "",
      },
      export_port: [] as TaggingItem[],
      abd: [] as any[],
      avm: [] as any[],
      proforma_invoice: [] as any[],
      packing_list: [] as any[],
      log_type: [],
      pim_specification: [],
      export_handling_company: [] as TaggingItem[],
      loadingProducts: true,
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    // const upload = ref<UploadInstance>();

    const removeFlag = ref(false);

    const uploadDisabled = ref({
      abd: false,
      avm: false,
      proforma_invoice: false,
      packing_list: false,
    });

    const rules = ref({
      export_handling_company: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      carrier_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      awb_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      export_port: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customs_inspection_dates: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                (value === "" || value === null) &&
                formData.value.customs_inspection == 1
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const itemTable = ref([]);

    const getShowInfo = async () => {
      const { data } =
        await ApiCustomDeclarationExport.showCustomDeclarationExport({
          id: route.params.id,
        });
      if (data.code == 0) {
        formData.value = data.data;
        options.value.defaultFormData.approval_date =
          data.data.declaration_date;
        itemTable.value = data.data.pim_specification;
        let ids: any = [];
        data.data.pim_specification.forEach((item) => {
          ids.push(item.sku_identifier);
        });
        getProductsData({
          sku_id: ids,
          merchant_id: 0,
        });
        let {
          abd,
          abd_name,
          avm,
          avm_name,
          proforma_invoice,
          proforma_invoice_name,
          packing_list,
          packing_list_name,
        } = data.data;
        if (abd != 0)
          options.value.abd = [
            {
              name: abd_name,
              url: abd,
            },
          ];
        if (avm != 0)
          options.value.avm = [
            {
              name: avm_name,
              url: avm,
            },
          ];
        if (proforma_invoice != 0)
          options.value.proforma_invoice = [
            {
              name: proforma_invoice_name,
              url: proforma_invoice,
            },
          ];
        if (packing_list != 0)
          options.value.packing_list = [
            {
              name: packing_list_name,
              url: packing_list,
            },
          ];
      }
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.pim_specification = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.pim_specification);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["export_declaration_export_port"],
      });
      if (data.code == 0) {
        options.value.export_port =
          data.data.export_declaration_export_port.items;
      }
    };

    const getShipmentCustomsHandlingOption = async () => {
      const { data } = await ApiShipments.getShipmentCustomsHandlingOption({});
      if (data.code == 0) {
        options.value.export_handling_company = data.data;
      }
    };

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([
        getShowInfo(),
        getTaggingData(),
        getShipmentCustomsHandlingOption(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          // formData.value.customs_inspection_address = null;
          let { customs_inspection_address, ...data } = formData.value;
          ApiCustomDeclarationExport.updateCustomDeclarationExport(data)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getFromInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const setUploadType = (type: string) => {
      uploadType.value = type;
    };

    const handleProgress = (e, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = true;
    };

    const handleSuccess = (response, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = false;
      if (response.code !== 0) {
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      formData.value[attributes] = response.data.file_id;
      formData.value[`${attributes}_name`] = response.data.file_name;
    };

    const handleError = (err, file, fileList, attributes) => {
      if (err) {
        uploadDisabled.value[attributes] = false;
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handlePreview = (sign) => {
      let name = "_blank";
      if (sign === "packing_list") {
        name = "_self";
      }
      uploadDisabled.value[sign] = true;
      ApiBase.getFile({
        id: formData.value[sign],
      })
        .then(({ data }) => {
          uploadDisabled.value[sign] = false;
          let fileURL;
          // var blob = new Blob([data], { type: "application/pdf" });
          if (window.webkitURL != undefined) {
            // webkit or chrome
            fileURL = window.webkitURL.createObjectURL(data);
          } else if (window.URL != undefined) {
            // mozilla(firefox)
            fileURL = window.URL.createObjectURL(data);
          }
          childWin = window.open(
            fileURL,
            name,
            "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          );
          childWin.onload = function () {
            // childWin.print();
            if (window.webkitURL != undefined) {
              // webkit or chrome
              window.webkitURL.revokeObjectURL(fileURL);
            } else if (window.URL != undefined) {
              // mozilla(firefox)
              window.URL.revokeObjectURL(fileURL);
            }
          };
        })
        .catch((error) => {
          uploadDisabled.value[sign] = false;
          console.log(error);
        });
    };

    const handleBeforeUpload = (file) => {
      const limit = 1024 * 1024 * 5;
      if (file.size > limit) {
        Swal.fire({
          text: t("common.uploadSizeErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          removeFlag.value = false;
        });
        removeFlag.value = true;
        return false;
      }
    };

    const statusClass = (status) => {
      return getExportDeclarationStatus(status, t);
    };

    const backToList = () => {
      commonBackToList(router, "/declaration-management/export-declaration");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      commonChangeDefaultDate,
      id,
      uploadType,
      uploadUrl,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      itemTable,
      uploadHeaders,
      uploadDisabled,
      removeFlag,
      getFromInfo,
      submit,
      setUploadType,
      handleProgress,
      handleSuccess,
      handleError,
      handlePreview,
      handleBeforeUpload,
      backToList,
      getProductIdData,
      getOfficialPrices,
      previewImages,
      statusClass,
    };
  },
  methods: {
    handleExceed(files, fileList, sign) {
      console.log(files, fileList, sign);
      (this.$refs[`upload-${sign}`] as any)!.clearFiles();
      const file = files[0];
      (this.$refs[`upload-${sign}`] as any)!.handleStart(file);
      (this.$refs[`upload-${sign}`] as any)!.submit();
    },
    handleRemove(file, fileList, sign, formData, options, removeFlag) {
      // 上传文件超出限制会在页面上删除文件，这里重新将之前的文件回显
      if (removeFlag && formData[sign]) {
        (this.$refs[`upload-${sign}`] as any)!.handleStart(options[sign][0]);
        return;
      }
      formData[sign] = 0;
      formData[`${sign}_name`] = "";
    },
    handleDeleteUpload(sign, formData) {
      (this.$refs[`upload-${sign}`] as any)!.clearFiles();
      formData[sign] = 0;
      formData[`${sign}_name`] = "";
    },
  },
});
